.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.PageContainer {
  padding: 15px;
  text-align: right;
}

.CenterPage {
  width: 80%;
  margin: 20px auto 20px;
  background: white;
  padding: 40px 40px 60px;
  border-radius: 10px;
}

.CaseProfile .Section {
  text-align: center;
  display: flex;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
}

.CaseProfile .Section .InfoCard {
  margin: 0 auto;
  width: 18rem;
  border-radius: 5px;
  padding: 15px;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0);
  box-shadow: -2px 5px 10px -7px rgba(0, 0, 0, 0.2);
}

.CaseProfile .CommentsSection {
  padding: 20px;
  margin: 0 auto;
  padding: 10px;
  width: 90%;
}

.CaseProfile .CommentsSection .Comment {
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
}

.CommentAuthor {
  font-weight: bold;
}

.TableContainer {
  margin: 0 auto;
  width: 100%;
  font-size: 13px;
  text-align: center;
}

.TableHeader th {
  padding: 20px;
  font-size: 12px;
}

/*Navbar*/

.Navbar {
  list-style-type: none;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  font-size: 11px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 4px 4px -3px rgba(18, 18, 18, 0.27);
  box-shadow: 0px 4px 4px -3px rgba(18, 18, 18, 0.27);
}

.Navbar li {
  float: right;
}

.Navbar li i {
  margin: 0 7px;
  font-size: 18px;
}

.Navbar li .link {
  display: block;
  color: black;
  text-align: center;
  padding: 16px 10px;
  text-decoration: none;
}

.Navbar .UserName {
  display: block;
  color: white;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  cursor: default;
  background-color: #277c8b;
}

.active {
  background-color: rgba(193, 193, 193, 0.396);
  box-shadow: inset 1px 1px 10px rgb(150, 149, 149);
}

.logout {
  background-color: rgba(255, 51, 51, 0.966);
}

.logOutIcon path {
  stroke: white;
}

/*End-Navbar*/

.EditButton {
  font-size: 20px;
  cursor: pointer;
}

.StatisticsContainer {
  margin: auto;
  display: flex;
  width: 86%;
}

.StatisticsContainer div {
  text-align: center;
  width: 100%;
  padding: 20px;
  color: black;
}

.SearchContainer {
  display: flex;
  padding: 10px;
  width: 98%
}

.SearchContainer input:focus {
  outline: none;
}

.SearchContainer .Search {
  width: 25rem;
  border: 1px #e0e0e0 solid;
  text-align: right;
}

.SearchContainer .NewObject {
  margin-left: 20px;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(0, 104, 209);
  color: white;
  margin-bottom: 10px;
}

.SearchContainer .NewObject:hover {
  cursor: pointer;
}

/*Login page*/

.Login *,
*::before,
*::after {
  box-sizing: border-box;
}

.ErrorMessage {
  color: rgb(252, 77, 77);
  text-align: center;
}

.Login h1 {
  font-weight: 700;
  color: #384047;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
}

.Login a {
  font-size: 0.98em;
  color: #8a97a0;
  text-decoration: none;
}

.ModalInput,
.ModalInput:focus {
  color: #384047;
  background-color: #e8eeef;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  border-radius: 4px;
  padding: 0.7em;
  margin-bottom: 1.2em;
  width: 100%;
}

.Login {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 6%;
  margin: 0;
}

.Login form {
  background-color: #fff;
  padding: 2em;
  padding-bottom: 3em;
  border-radius: 8px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.Login form input {
  color: #384047;
  background-color: #e8eeef;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  border-radius: 4px;
  padding: 1em;
  margin-bottom: 1.2em;
  width: 100%;
  text-align: right;
}

.Login form input:focus {
  outline: none;
}

.Login .LoginButton {
  font-weight: 600;
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #324ab2;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 0.8em;
  margin-top: 1em;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.Login .LoginButton:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.Login .LoginButton span {
  position: relative;
  z-index: 1;
}

.Login .LoginButton .circle {
  position: absolute;
  z-index: 0;
  background-color: #35a556;
  border-radius: 50%;
  transform: scale(0);
  opacity: 5;
  height: 50px;
  width: 50px;
}

.Login .LoginButton .circle.animate {
  animation: grow 0.5s linear;
}

.insideCard {
  border-radius: 7px;
  border: 1.5px solid rgb(229 229 229);
  background-color: rgba(238, 238, 238, 0.544);
  padding: 20px 0px 20px 0px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
  padding-right: 20px;
}

.input-container label {
  margin-bottom: 10px;
}

.input {
  border: 1px solid rgba(59, 59, 59, 0.637);
  padding: 10px;
  border-radius: 5px;
  text-align: right;
}

.input:focus {
  outline: 1px #cccccc;
}

.sendButton {
  padding: 5px;
  font-size: 15px;
  margin-left: 20px;
}

.sendButtonEntry {
  position: absolute;
  right: 20px;
  bottom: 8px;
}

.input-group-row {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 20px;
}

.button1 {
  border: none;
  border-radius: 3px;
  background-color: rgb(0, 128, 255);
  border: 1px solid rgb(0, 107, 214);
  color: white;
  height: 30px;
  width: 70px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
}

.button2 {
  border: none;
  border-radius: 3px;
  background-color: none;
  border: 1px solid rgb(180, 180, 180);
  color: rgb(0, 0, 0);
  height: 30px;
  width: 150px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
}



.PageContainer {
  background-color: #fafafa;
  padding: 20px;
}

.CaseProfile {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
}

.Section {
  margin-top: 20px;
}

.InfoCard {
  margin-bottom: 10px;
  background-color: #f5f5f5;
  padding: 10px;
}

.InfoCard h4 {
  margin-top: 0;
  color: #333;
}

.InfoCard p {
  margin-top: 5px;
  color: #666;
}

.InfoCard .BiEdit {
  cursor: pointer;
  color: #324ab2;
}

.PDFViewer {
  width: 400px;
  height: 300px;
}
